<template>
  <div>
    <div class="container-pa16">
      <vueper-slides
        ref="adBanner"
        class="carousel-ad no-shadow"
        autoplay
        fixed-height="160px"
        :arrows="false"
      >
        <vueper-slide
          v-for="item in ad"
          :key="item.id"
          :image="`https://s3lvelect.s3.ap-east-1.amazonaws.com/${item.photo}`"
          :link="item.link"
        />
      </vueper-slides>
      <div class="inform center">
        <h1 class="title">{{ store.name }}</h1>
        <div class="inform-detail">
          <div class="margin-bottom-16">
            <div class="flex-container space-between flex-center">
              <img :src="require('@/assets/imgs/svg/0527-l.svg')" />
              <span>{{ store.time }}</span>
            </div>
            <hr class="gray-hr" />
          </div>
          <div class="margin-bottom-16">
            <div class="flex-container space-between flex-center">
              <img :src="require('@/assets/imgs/svg/0527-m.svg')" />
              <span>{{ store.phone }}</span>
            </div>
            <hr class="gray-hr" />
          </div>
          <div class="margin-bottom-16">
            <div class="flex-container space-between flex-center">
              <img :src="require('@/assets/imgs/svg/0527-n.svg')" />
              <span>{{ store.address }}</span>
            </div>
            <hr class="gray-hr" />
          </div>
          <div class="margin-bottom-8">
            <div class="flex-container space-between flex-center">
              <img :src="require('@/assets/imgs/svg/0527-n.svg')" />
              <span>{{ store.url }}</span>
            </div>
            <hr class="gray-hr" />
          </div>
        </div>
        <span class="text-1250">可借：{{ store.borrow }} / 可還：{{ store.return }}</span>
        <br />
        <br />
      </div>
      <center>
        <button
          type="button"
          class="capsule-button text-1250 capsule-big"
        >
          立即租借
        </button>
      </center>
      <div class="bottom-name">
        <img :src="require('@/assets/imgs/svg/0527-c.svg')" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            ad: [
                {
                    id: "",
                    photo: "test-2.jpg",
                    link: "",
                },
            ],
            store: {
                name: "店家名稱",
                time: "輸入營業時間",
                phone: "輸入聯絡電話",
                address: "輸入地址",
                url: "輸入官網",
                borrow: "4",
                return: "4",
            },
        };
    },
};
</script>
