<template>
  <div>
    <Store></Store>
    <div class="light-box flex-container flex-center center flex-center-h">
      <div class="box flex-container flex-column flex-center center">
        <span class="text-1500 text-b title">提醒</span>
        <span class="msg text-1250">請申請LINE PAY並綁定</span>
        <button
          type="button"
          class="capsule-button text-875 capsule-small check"
        >
          確認
        </button>
      </div>
      <div class="button"></div>
    </div>
    <div class="block"></div>
  </div>
</template>

<script>
import Store from "@/components/Store.vue";
export default {
    data() {
        return {
            ad: [
                {
                    photo: "test-2.jpg",
                },
                {
                    photo: "test-2.jpg",
                },
                {
                    photo: "test-2.jpg",
                },
            ],
            store: {
                name: "店家名稱",
                time: "輸入營業時間",
                phone: "輸入聯絡電話",
                address: "輸入地址",
                url: "輸入官網",
                borrow: "4",
                return: "4",
            },
        };
    },
    components: {
        Store,
    },
};
</script>
